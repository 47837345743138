import React, { useState } from "react";
import { Code } from "lucide-react";
import SkillBar from "./SkillBar";

const Skills = ({ theme, visibleSections, portfolioData }) => {
  const [activeSkillCategory] = useState("All");

  const filteredSkills =
    activeSkillCategory === "All"
      ? portfolioData.skills
      : portfolioData.skills.filter(
          (skill) => skill.category === activeSkillCategory
        );

  const groupedSkills = filteredSkills.reduce((acc, skill) => {
    if (!acc[skill.category]) {
      acc[skill.category] = [];
    }
    acc[skill.category].push(skill);
    return acc;
  }, {});

  return (
    <section
      id="skills"
      className={`mb-20 transition-opacity duration-1000 ${
        visibleSections.skills ? "opacity-100" : "opacity-0"
      }`}
    >
      <h3 className="text-3xl font-bold mb-8 flex items-center">
        <Code className="mr-2" /> Skills & Technologies
      </h3>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {Object.entries(groupedSkills).map(([category, skills]) => (
          <div
            key={category}
            className={`p-6 rounded-lg ${
              theme === "dark" ? "bg-gray-800" : "bg-gray-100"
            }`}
          >
            <h4 className="text-lg font-semibold mb-4 text-blue-600 border-b border-blue-200 pb-2">
              {category}
            </h4>
            <div className="space-y-4">
              {skills.map((skill, index) => (
                <SkillBar
                  key={index}
                  name={skill.name}
                  level={skill.level}
                  category={skill.category}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
