const SkillBar = ({ name, level, category }) => (
  <div className="mb-2">
    <div className="flex justify-between items-center mb-1">
      <span className="font-medium text-sm">{name}</span>
      <span className="text-xs text-gray-500">{level}%</span>
    </div>
    <div className={`h-2 rounded-full bg-gray-200 overflow-hidden`}>
      <div
        className="h-full bg-blue-600 rounded-full transition-all duration-500"
        style={{ width: `${level}%` }}
      />
    </div>
  </div>
);

export default SkillBar;
