import React, { useState } from "react";
import {
    Sun,
    Moon,
    Menu,
    X,
  } from "lucide-react";
  const Nav = ({ portfolioData, theme, setTheme }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
      };
      
    return (
        <nav className={`${
          theme === "dark" 
            ? "bg-blue-900 text-white" 
            : "bg-blue-600 text-white"
        } p-4 sticky top-0 z-10`}>
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">
            {portfolioData.personalInfo.name}
          </h1>
          
          {/* Mobile menu button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop menu */}
          <div className="hidden md:flex items-center space-x-4">
            <a href="#about" className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"}`}>
              About
            </a>
            <a href="#skills" className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"}`}>
              Skills
            </a>
            <a href="#projects" className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"}`}>
              Projects
            </a>
            <a href="#experience" className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"}`}>
              Experience
            </a>
            <a href="#contact" className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"}`}>
              Contact
            </a>
            <button
              onClick={toggleTheme}
              className={`p-1 rounded-full ${
                theme === "dark" 
                  ? "bg-blue-800 hover:bg-blue-700" 
                  : "bg-blue-700 hover:bg-blue-800"
              }`}
            >
              {theme === "light" ? <Moon size={20} /> : <Sun size={20} />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:hidden absolute top-full left-0 right-0 ${
            theme === "dark" 
              ? "bg-blue-900 border-blue-800" 
              : "bg-blue-600 border-blue-500"
          } border-t`}
        >
          <div className="flex flex-col space-y-2 p-4">
            <a
              href="#about"
              className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"} py-2`}
              onClick={() => setIsMenuOpen(false)}
            >
              About
            </a>
            <a
              href="#skills"
              className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"} py-2`}
              onClick={() => setIsMenuOpen(false)}
            >
              Skills
            </a>
            <a
              href="#projects"
              className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"} py-2`}
              onClick={() => setIsMenuOpen(false)}
            >
              Projects
            </a>
            <a
              href="#experience"
              className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"} py-2`}
              onClick={() => setIsMenuOpen(false)}
            >
              Experience
            </a>
            <a
              href="#contact"
              className={`${theme === "dark" ? "hover:text-blue-300" : "hover:text-blue-200"} py-2`}
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
            </a>
            <button
              onClick={() => {
                toggleTheme();
                setIsMenuOpen(false);
              }}
              className={`p-1 rounded-full ${
                theme === "dark" 
                  ? "bg-blue-800 hover:bg-blue-700" 
                  : "bg-blue-700 hover:bg-blue-800"
              } w-8 h-8 flex items-center justify-center`}
            >
              {theme === "light" ? <Moon size={20} /> : <Sun size={20} />}
            </button>
          </div>
        </div>
      </nav>
    )
}

export default Nav;
