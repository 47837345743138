import { User } from 'lucide-react';

const About = ({ theme, visibleSections, portfolioData }) => {
  return (
    <section
      id="about"
      className={`mb-20 transition-opacity duration-1000 ${
        visibleSections.about ? "opacity-100" : "opacity-0"
      }`}
    >
      <h3 className="text-3xl font-bold mb-8 flex items-center">
        <User className="mr-2" /> About Me
      </h3>
      <div
        className={`p-6 rounded-lg ${
          theme === "dark" ? "bg-gray-800" : "bg-gray-100"
        }`}
      >
        <p className="text-lg">{portfolioData.about}</p>
        <br />
        <p className="text-lg">{portfolioData.aboutExtra1}</p>
      </div>
    </section>
  );
};

export default About;
