import React, {  } from "react";
import {
  Terminal,
  Youtube,
  Calendar,
} from "lucide-react";

const Header = ({ theme, portfolioData }) => {
    return (
        <header className={`py-20 ${
          theme === "dark"
            ? "bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900"
            : "bg-gradient-to-r from-blue-600 to-blue-400"
        } text-white`}>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/3 mb-8 md:mb-0">
              <img
                src={portfolioData.personalInfo.profilePicture}
                alt={portfolioData.personalInfo.name}
                className="rounded-full w-48 h-48 object-cover border-4 border-white shadow-lg"
              />
            </div>
            <div className="md:w-2/3 text-center md:text-left">
              <h2 className="text-4xl md:text-5xl font-bold mb-4">
                {portfolioData.personalInfo.name}
              </h2>
              <p className="text-xl md:text-2xl mb-4">
                {portfolioData.personalInfo.title}
              </p>
              <p className="text-lg md:text-xl mb-6 italic">
                {portfolioData.personalInfo.subtitle}
              </p>
              <div className="flex flex-wrap justify-center md:justify-start gap-4">
                <a
                  href="https://calendly.com/mamerto/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    theme === "dark" 
                      ? "bg-green-700 hover:bg-green-600" 
                      : "bg-green-500 hover:bg-green-600"
                  } text-white px-6 py-2 rounded-full font-medium transition duration-300 inline-flex items-center text-sm`}
                >
                  <Calendar className="w-4 h-4 mr-2" /> Schedule a Call
                </a>
                <a
                  href={portfolioData.personalInfo.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    theme === "dark" 
                      ? "bg-red-800 hover:bg-red-700" 
                      : "bg-red-500 hover:bg-red-600"
                  } text-white px-6 py-2 rounded-full font-medium transition duration-300 inline-flex items-center text-sm`}
                >
                  <Youtube className="w-4 h-4 mr-2" /> YouTube
                </a>
                <a
                  href={portfolioData.personalInfo.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    theme === "dark" 
                      ? "bg-gray-900 hover:bg-gray-800" 
                      : "bg-gray-800 hover:bg-gray-900"
                  } text-white px-6 py-2 rounded-full font-medium transition duration-300 inline-flex items-center text-sm`}
                >
                  <Terminal className="w-4 h-4 mr-2" /> GitHub
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
};

export default Header;
