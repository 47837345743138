import React, {  } from "react";
import {
  Linkedin,
  Mail,
  Globe,
} from "lucide-react";

const Contact = ({ visibleSections, portfolioData }) => {
    return (
        <section
          id="contact"
          className={`text-center transition-opacity duration-1000 ${
            visibleSections.contact ? "opacity-100" : "opacity-0"
          }`}
        >
          <h3 className="text-3xl font-bold mb-8">More Ways to Connect</h3>
          <p className="mb-8">
            Have a question or want to discuss a potential collaboration? Reach out through any of these channels:
          </p>
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            <a
              href={`mailto:${portfolioData.personalInfo.email}`}
              className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300 flex items-center"
            >
              <Mail className="mr-2" /> Email Me
            </a>
            <a
              href={portfolioData.personalInfo.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300 flex items-center"
            >
              <Linkedin className="mr-2" /> LinkedIn
            </a>
            <a
              href={portfolioData.personalInfo.website}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300 flex items-center"
            >
              <Globe className="mr-2" /> Website
            </a>
          </div>
        </section>
    )
}

export default Contact;
