import React, { useState, useEffect } from "react";
import portfolioData from "./portfolio-data.json";
import "react-vertical-timeline-component/style.min.css";
import About from './ui/components/About';
import Skills from "./ui/components/Skills";
import Projects from './ui/components/Projects';
import Experience from "./ui/components/Experience";
import Contact from "./ui/components/Contact";
import Header from "./ui/components/Header";
import Nav from "./ui/components/Nav";

const Portfolio = () => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'dark';
  });
  const [visibleSections, setVisibleSections] = useState({});

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleSections((prev) => ({ ...prev, [entry.target.id]: true }));
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    document.querySelectorAll("section[id]").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div
      className={`min-h-screen ${
        theme === "dark" ? "bg-gray-900 text-white" : "bg-white text-gray-900"
      }`}
    >
      <Nav theme={theme} setTheme={setTheme} portfolioData={portfolioData} />

      <Header theme={theme} visibleSections={visibleSections} portfolioData={portfolioData} />

      <main className="max-w-6xl mx-auto py-12 px-4">
        <div className="container mx-auto px-4 py-8">
          <About theme={theme} visibleSections={visibleSections} portfolioData={portfolioData} />
        </div>

        <Skills theme={theme} visibleSections={visibleSections} portfolioData={portfolioData} />

        <Projects theme={theme} visibleSections={visibleSections} portfolioData={portfolioData} />

        <Experience theme={theme} portfolioData={portfolioData} />

        <Contact visibleSections={visibleSections} portfolioData={portfolioData} />
      </main>

      <footer
        className={`mt-20 py-8 ${
          theme === "dark" ? "bg-gray-800" : "bg-gray-100"
        }`}
      >
        <div className="max-w-6xl mx-auto text-center">
          <p>
            &copy; {new Date().getFullYear()} {portfolioData.personalInfo.name} All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Portfolio;
