import React, {  } from "react";
import {
  Briefcase,
  Award,
} from "lucide-react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

const Experience = ({ theme, portfolioData }) => {
    return (
        <section id="experience" className="mb-20">
          <h3 className="text-3xl font-bold mb-8 flex items-center">
            <Award className="mr-2" /> Professional Experience
          </h3>
          <VerticalTimeline
            lineColor={theme === "dark" ? "#4B5563" : "#E5E7EB"}
          >
            {portfolioData.experiences.map((exp, index) => (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: theme === "dark" ? "#1F2937" : "#F3F4F6",
                  color: theme === "dark" ? "#fff" : "#000",
                }}
                contentArrowStyle={{
                  borderRight: `7px solid ${
                    theme === "dark" ? "#1F2937" : "#F3F4F6"
                  }`,
                }}
                date={exp.period}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                icon={<Briefcase />}
              >
                <h3 className="vertical-timeline-element-title font-bold">
                  {exp.title}
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  {exp.company}
                </h4>
                <ul className="mt-4 list-disc list-inside">
                  {exp.achievements.map((achievement, i) => (
                    <li key={i}>{achievement}</li>
                  ))}
                </ul>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </section>
    )
}

export default Experience;
