import React, { useState } from "react";
import {
  Briefcase,
  ExternalLink,
} from "lucide-react";

const Projects = ({ theme, visibleSections, portfolioData }) => {
    const [activeTab, setActiveTab] = useState("All");
  const filteredProjects =
    activeTab === "All"
      ? portfolioData.projects
      : portfolioData.projects.filter(
          (project) => project.category === activeTab
        );

  return (
    <section
      id="projects"
      className={`mb-20 transition-opacity duration-1000 ${
        visibleSections.projects ? "opacity-100" : "opacity-0"
      }`}
    >
      <h3 className="text-3xl font-bold mb-8 flex items-center">
        <Briefcase className="mr-2" /> Featured Projects
      </h3>
      <div className="mb-6 flex flex-wrap gap-4">
        <button
          onClick={() => setActiveTab("All")}
          className={`px-4 py-2 rounded-full ${
            activeTab === "All"
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-800"
          } transition-colors duration-300`}
        >
          All
        </button>
        {[
          "Content Creation",
          "Open Source",
          "Web App",
          "Mobile App",
          "Browser Extension",
          "POS System",
          "Enterprise System",
        ].map((category) => (
          <button
            key={category}
            onClick={() => setActiveTab(category)}
            className={`px-4 py-2 rounded-full ${
              activeTab === category
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800"
            } transition-colors duration-300`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="grid md:grid-cols-2 gap-8">
        {filteredProjects.map((project, index) => (
          <div
            key={index}
            className={`p-6 rounded-lg ${
              theme === "dark" ? "bg-gray-800" : "bg-gray-100"
            } transform transition duration-300 hover:scale-105 hover:shadow-lg`}
          >
            <h4 className="text-xl font-semibold mb-2">{project.name}</h4>
            <p className="mb-4">{project.description}</p>
            <p className="text-sm mb-4">
              <strong>Tech Stack:</strong> {project.tech}
            </p>
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 flex items-center"
            >
              View Project <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
